<template>
  <div class="job">
    <Crumbs></Crumbs>
    <el-row class="job-top"
      ><el-button class="job-top-btn" size="small" type="primary" @click="handle_export"
        >导出</el-button
      ></el-row
    >
    <el-row class="job-btm">
      <table id="job-table">
        <tbody>
          <tr>
            <th rowspan="10" class="table-zz">在职人员分析</th>
          </tr>
          <tr>
            <th colspan="10" class="table-top">在职人员分析</th>
          </tr>
          <tr>
            <th rowspan="3">总人数</th>
            <th rowspan="3">性别分析</th>
            <th>男</th>
            <th>女</th>
            <th rowspan="3">年龄结构分析</th>
            <th>25岁以下</th>
            <th>26~35岁</th>
            <th>36~45岁</th>
            <th>46岁以上</th>
            <th>平均年龄</th>
          </tr>
          <tr>
            <td>{{ jobInfo.man }}人</td>
            <td>{{ jobInfo.girl }}人</td>
            <td>{{ jobInfo.age25 }}人</td>
            <td>{{ jobInfo.age26_35 }}人</td>
            <td>{{ jobInfo.age36_45 }}人</td>
            <td>{{ jobInfo.age46 }}人</td>
            <td rowspan="2">{{ jobInfo.average }}</td>
          </tr>
          <template v-if="jobInfo.gender">
            <tr>
              <td>{{ jobInfo.gender.numa }}%</td>
              <td>{{ jobInfo.gender.numb }}%</td>
              <td>{{ jobInfo.age.numa }}%</td>
              <td>{{ jobInfo.age.numb }}%</td>
              <td>{{ jobInfo.age.numc }}%</td>
              <td>{{ jobInfo.age.numd }}%</td>
            </tr>
          </template>
          <tr>
            <th colspan="10" class="table-top"></th>
          </tr>
          <tr>
            <th rowspan="4">受教育结构分析</th>
          </tr>
          <tr>
            <th colspan="2">硕士以上</th>
            <th colspan="2">本科</th>
            <th colspan="2">大专</th>
            <th colspan="3">大专以下</th>
          </tr>
          <tr>
            <td colspan="2">{{ jobInfo.suoshi }}人</td>
            <td colspan="2">{{ jobInfo.benke }}人</td>
            <td colspan="2">{{ jobInfo.dazhuang }}人</td>
            <td colspan="3">{{ jobInfo.dazhuangyixia }}人</td>
          </tr>
          <template v-if="jobInfo.gender">
            <tr>
              <td colspan="2">{{ jobInfo.learn.numa }}%</td>
              <td colspan="2">{{ jobInfo.learn.numb }}%</td>
              <td colspan="2">{{ jobInfo.learn.numc }}%</td>
              <td colspan="3">{{ jobInfo.learn.numd }}%</td>
            </tr>
          </template>
        </tbody>
      </table>
    </el-row>
  </div>
</template>

<script>
export default {
  components: {
    Crumbs: () => import("@/components/Crumbs.vue"),
  },
  data() {
    return {
      jobInfo: {},
    };
  },
  created() {
    this.getData();
  },
  methods: {
    // 导出
    handle_export() {
      this.$api.user.exportAnalysis().then((res) => {
        const fileName = `在职人员分析统计.xlsx`;
        const blob = new Blob([res], { type: "application/vnd.ms-excel" });
        if (navigator.msSaveBlob) {
          navigator.msSaveBlob(blob, fileName);
        } else {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = fileName;
          link.click();
          URL.revokeObjectURL(link.href);
          this.$message({
            message: "导出成功!",
            type: "success",
          });
        }
      });
    },

    // 获取数据
    async getData() {
      const res = await this.$api.user.getStatisticAnalysis();
      console.log(res);
      this.jobInfo = res.data;
      if (this.jobInfo.man) {
        this.jobInfo.gender = this.seekNum(this.jobInfo.man, this.jobInfo.girl);
        this.jobInfo.age = this.seekNum(
          this.jobInfo.age25,
          this.jobInfo.age26_35,
          this.jobInfo.age36_45,
          this.jobInfo.age46
        );
        this.jobInfo.learn = this.seekNum(
          this.jobInfo.suoshi,
          this.jobInfo.benke,
          this.jobInfo.dazhuang,
          this.jobInfo.dazhuangyixia
        );
      }
    },
    seekNum(a = 0, b = 0, c = 0, d = 0) {
      let sum = a + b + c + d;
      let numa = Number(((a / sum) * 100).toFixed(2));
      let numb = Number(((b / sum) * 100).toFixed(2));
      let numc = Number(((c / sum) * 100).toFixed(2));
      let numd = Number(((d / sum) * 100).toFixed(2));
      return { numa, numb, numc, numd };
    },
  },
};
</script>

<style scoped lang="scss">
.job {
  padding: 5px;

  .job-top {
    height: 75px;
    display: flex;
    justify-content: end;
    position: relative;

    .job-top-btn {
      position: absolute;
      right: 40px;
      top: 20px;
      width: 90px;
    }
  }

  .job-btm {
    padding-left: 20px;
    width: 1600px;

    #job-table {
      width: 100%;
      text-align: center;

      th,
      tr,
      td {
        border: 1px solid #080808;
        color: #080808;
      }

      td,
      th {
        height: 50px;
        padding: 20px;
      }

      .table-zz {
        background-color: #fde9d9;
        width: 100px;
      }

      .table-top {
        background-color: #dce6f1;
      }
    }
  }
}
</style>
